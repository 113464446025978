export const LIST_CHAT_SUCCESS = "LIST_CHAT_SUCCESS";
export const LIST_ACTIVE_CHAT_SUCCESS = "LIST_ACTIVE_CHAT_SUCCESS";
export const CLEAR_ACTIVE_CHAT_SUCCESS = "CLEAR_ACTIVE_CHAT_SUCCESS";

export const SET_CHAT_LOADING = "SET_CHAT_LOADING";
export const SET_CHAT_LOADING_ERROR = "SET_CHAT_LOADING_ERROR";

export const SET_MESSAGE_LOADING = "SET_MESSAGE_LOADING";
export const SET_MESSAGE_LOADING_ERROR = "SET_MESSAGE_LOADING_ERROR";

export const LIST_CUSTOMER_CHAT_SUCCESS_SEARCH =
  "LIST_CUSTOMER_CHAT_SUCCESS_SEARCH";
