export const LIST_SOILTABLE_CATEGORY_SUCCESS = "LIST_SOILTABLE_CATEGORY_SUCCESS";
export const LIST_SOILTABLE_REQUEST_CATEGORY_SUCCESS = "LIST_SOILTABLE_REQUEST_CATEGORY_SUCCESS";
export const SET_SOILTABLE_CATEGORY_LOADING = "SET_SOILTABLE_CATEGORY_LOADING";
export const SET_SOILTABLE_CATEGORY_ERROR = "SET_SOILTABLE_CATEGORY_ERROR";
export const CREATE_SOILTABLE_CATEGORY = "CREATE_SOILTABLE_CATEGORY";

export const CREATE_SOILTABLE_CATEGORY_ADMIN = "CREATE_SOILTABLE_CATEGORY_ADMIN";
export const UPDATE_SOILTABLE_CATEGORY_ADMIN = "UPDATE_SOILTABLE_CATEGORY_ADMIN";
export const DELETE_SOILTABLE_CATEGORY_ADMIN = "DELETE_SOILTABLE_CATEGORY_ADMIN";

export const LIST_SOILTABLE_DETAIL_SUCCESS = "LIST_SOILTABLE_DETAIL_SUCCESS";

export const CREATE_SOILTABLE_PRODUCT_ADMIN = "CREATE_SOILTABLE_PRODUCT_ADMIN"
export const APPROVE_SOILTABLE_CATEGORY_ADMIN = "APPROVE_SOILTABLE_CATEGORY_ADMIN"

export const UPDATE_REQUEST_SOILTABLE_CATEGORY = "UPDATE_REQUEST_SOILTABLE_CATEGORY";
