export const LIST_TRAILER_SUCCESS = "LIST_TRAILER_SUCCESS";
export const CREATE_TRAILER_SUCCESS = "CREATE_TRAILER_SUCCESS";
export const UPDATE_TRAILER_SUCCESS = "UPDATE_TRAILER_SUCCESS";
export const DELETE_TRAILER_SUCCESS = "DELETE_TRAILER_SUCCESS";
export const TRAILER_CHECKBOX_UPDATE = "TRAILER_CHECKBOX_UPDATE";
export const SET_TRAILER_LOADING = "SET_TRAILER_LOADING";
export const SET_TRAILER_LOADING_ERROR = "SET_TRAILER_LOADING_ERROR";
export const LIST_TRAILER_SUCCESS_SEARCH = "LIST_TRAILER_SUCCESS_SEARCH";

export const LIST_TRAILER_DETAIL_SUCCESS = "LIST_TRAILER_DETAIL_SUCCESS";
export const UPDATE_TRAILER_DETAIL_SUCCESS = "UPDATE_TRAILER_DETAIL_SUCCESS";
